<template>
    <div>
      <!-- guardar equipo -->
      <Base-modal
      :modalOverlay="true"
      @exit="exit()"
      :modal="true"
      :headingTitle="$t('Equipos')"
      size="lg"
      >
      <template #content>
        <div class="mx-2 flex flex-col ">
          <!-- datos del equipo -->
          <FormExpress  @getValues="setForm($event)" ref="refForm" :inputs="equipmentInputsTemplate"/>
          <div v-if="formData?.equipment_state_id" class="my-2 w-44 mr-2">
            <BaseButton
            @click="saveEquipment()"
            :disabled="(refForm && !formValid) || loading"
            :loading="loading"
            textColor="text-white"
            background="bg-black"
            :text="$t('Siguiente')" />
          </div>
          <div class="mb-2" v-if="!objEmpty(errorMessage)">
              <SimpleAlert color="gray" border="border-b-4">
                <template v-slot:text>
                  <div  v-for="(error, index) in errorMessage" :key="index">
                    {{error.length > 0 && typeof error === 'object'? error[0] : error}}
                  </div>
                </template>
              </SimpleAlert>
          </div>
        </div>
      </template>
      </Base-modal>

    </div>
</template>

<script>
import {
  ref, computed, watch, getCurrentInstance, onMounted,
} from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import usualHook from '@/hooks/core/usualHook';
import { saveEquipmentRepo } from '@/repositories/Core/EquipmentManagementRepository';
import { EQUIPMENT_STATES } from '@/services/Utils/State';
import { TYPE_TEMPLATES } from '@/services/Utils/Templates';
import { objEmpty, hasLocalStorage } from '@/services/Utils/Objects';

export default {
  setup(_, { emit }) {
    const store = useStore();
    const enterprise = computed(() => store.state.equipments.enterprise);
    const equipmentInputsTemplate = computed(() => store.state.equipments.equipmentInputsTemplate);
    const cacheEquipmentInputsTemplate = computed(() => store.state.equipments.cacheEquipmentInputsTemplate);
    const currentEquipment = computed(() => store.state.equipments.currentEquipment);
    const agreement = computed(() => store.state.equipments.agreement);
    const setPropEquipment = async (payload) => {
      const prop = await store.dispatch('equipments/setPropEquipment', payload);
      return prop;
    };

    const router = useRouter();
    const route = useRoute();
    const route_from_path = ref('');
    const isAutorize = ref(false);
    const instance = ref({});

    onMounted(() => {
      instance.value = getCurrentInstance().appContext.config.globalProperties;
    });

    const exit = async (save = false) => {
      // si cuando me salgo, vengo de un equipo que esta en autorizar, tengo que resetear valores
      if (!save) {
        // cuando vengo de guardar un equipo (por eso el flag save, no hace falta setear)
        if (isAutorize.value && !objEmpty(currentEquipment.value)) {
          await store.dispatch('equipments/setPropEquipment', {
            key: 'equipmentInputsTemplate', index: 'equipment_state_id', prop: 'value', value: null,
          });
          await store.dispatch('equipments/setPropEquipment', {
            key: 'equipmentInputsTemplate', index: 'equipment_state_id', prop: 'disabled', value: false,
          });
          // await store.dispatch('equipments/hiddenFieldsToNegative', true);
          await refForm.value.reset();
          isAutorize.value = false;
        }
      } else if (isAutorize.value) {
        isAutorize.value = false;
      }

      if (route_from_path.value) {
        router.go(-1);
      } else {
        const { identificacion, order, hash } = route.params;
        if (agreement.value) {
          router.push({
            name: 'agreementManagement',
            params: {
              agreement_id: agreement.value, identificacion, order, hash,
            },
          });
        } else {
          router.push({ name: 'equipmentManagement', params: { identificacion, order } });
        }
      }
    };

    const refForm = ref(null);
    const formValid = computed(() => refForm.value.valid);
    const formData = ref({});
    const currentState = computed(() => formData.value.equipment_state_id);
    watch(() => currentState.value, (newVal) => {
      // cuando la gestion no sea para recuperar equipo, escondo los demas inputs
      // OJOO!!! ESTO SETEARA SIEMPRE EL OBJETO POR LO TANTO EL COMPUTED QUE ARMA EL FORM SIEMPRE ESTARA ACTIVO
      if (newVal !== EQUIPMENT_STATES.RECUPERADO && newVal !== EQUIPMENT_STATES.AUTORIZAR) {
        store.dispatch('equipments/hiddenFieldsToNegative', true);
        // como es un negativo, le quito la regla de validacion requerida para que el form se pueda enviar
        Object.keys(equipmentInputsTemplate.value).forEach((k) => {
          if (Object.prototype.hasOwnProperty.call(cacheEquipmentInputsTemplate.value[k], 'rules')) {
            const { rules } = cacheEquipmentInputsTemplate.value[k];
            const indexRequired = rules.findIndex((val) => val.required);
            if (indexRequired > -1) {
              const removeRequired = rules.map((val) => val);
              removeRequired[indexRequired].required = false;
              setPropEquipment({
                key: 'equipmentInputsTemplate', index: k, prop: 'rules', value: removeRequired,
              });
            }
          }
        });
      } else if (Object.keys(equipmentInputsTemplate.value).some((k) => equipmentInputsTemplate.value[k].hidden === true)) {
        store.dispatch('equipments/hiddenFieldsToNegative', false);
        // como es un positivo, le restablezco la regla de validacion requerido
        Object.keys(equipmentInputsTemplate.value).forEach((k) => {
          if (Object.prototype.hasOwnProperty.call(cacheEquipmentInputsTemplate.value[k], 'rules')) {
            const { rules } = cacheEquipmentInputsTemplate.value[k];
            const indexRequired = rules.findIndex((val) => val.required === false);
            if (indexRequired > -1) {
              const removeRequired = rules.map((val) => val);
              removeRequired[indexRequired].required = true;
              setPropEquipment({
                key: 'equipmentInputsTemplate', index: k, prop: 'rules', value: removeRequired,
              });
            }
          }
        });
      }
    });

    const setForm = (form) => {
      formData.value = form;
    };
    const prepareToAuthorize = async () => {
      const { equipment_state_id } = store.state.equipments.equipmentInputsTemplate;
      if (!equipment_state_id) {
        exit();
      } else {
        await cleanTemplate();
        await setPropEquipment({
          key: 'equipmentInputsTemplate', index: 'equipment_state_id', prop: 'value', value: EQUIPMENT_STATES.AUTORIZAR,
        });
        await setPropEquipment({
          key: 'equipmentInputsTemplate', index: 'equipment_state_id', prop: 'disabled', value: true,
        });
        formData.value.equipment_state_id = EQUIPMENT_STATES.AUTORIZAR;
      }
    };
    const setTemplate = async () => {
      let result = {};
      let templates;
      if (objEmpty(enterprise.value)) {
        const enterpriseLs = await hasLocalStorage({ key: 'enterprise' });
        if (enterpriseLs) {
          await setPropEquipment({ key: 'enterprise', value: enterpriseLs });
          const { templates: templatesAlias } = enterprise.value;
          templates = templatesAlias;
        }
      } else {
        const { templates: templatesAlias } = enterprise.value;
        templates = templatesAlias;
      }

      if (objEmpty(equipmentInputsTemplate.value)) {
        const formTemplate = templates.filter((val) => val.type_template_id === TYPE_TEMPLATES.FORMULARIO_GESTION_EQUIPOS)[0];
        const buildTemplate = {};
        JSON.parse(formTemplate.template).forEach((val) => {
          const key = Object.keys(val)[0];
          buildTemplate[key] = val[key];
        });
        result = await setPropEquipment({ key: 'equipmentInputsTemplate', value: buildTemplate });
        await setPropEquipment({ key: 'cacheEquipmentInputsTemplate', value: buildTemplate });
      } else {
        result = equipmentInputsTemplate.value;
      }
      return result;
    };
    const cleanTemplate = async () => {
      let enterprise_id = null;
      // si esta vacio el equipo actual (vuex), trato de buscarlo en el local storage, asi le saco el id
      // de la empresa y los valores del equipo para completar
      if (objEmpty(currentEquipment.value)) {
        const currentEquipmentLs = await hasLocalStorage({ key: 'currentEquipment' });
        if (currentEquipmentLs) {
          const { enterprise_id: id } = currentEquipmentLs;
          enterprise_id = id;
          await setPropEquipment({ key: 'currentEquipment', value: currentEquipmentLs });
        }
      } else {
        const { enterprise_id: id } = currentEquipment.value;
        enterprise_id = id;
      }
     
      if (templateTraduccionEquipos.value.length > 0) {
        templateTraduccionEquipos.value.forEach((val) => {
          const translate = val.translateKey;
          if (equipmentInputsTemplate.value[translate] && equipmentInputsTemplate.value[translate].value) {
            setPropEquipment({
              key: 'equipmentInputsTemplate', index: translate, prop: 'value', value: null,
            });
            setPropEquipment({
              key: 'equipmentInputsTemplate', index: translate, prop: 'disabled', value: false,
            });
          }
        });
      }
      const reset = await refForm.value.reset();
      return reset;
    };

    const { equipment_states, getEquipmentStates } = usualHook();
    const getStates = async (autorize = false) => {
      await getEquipmentStates();
      const WITHOUT_STATES = [
        EQUIPMENT_STATES.RECUPERADO_L,
        EQUIPMENT_STATES.NEGATIVO_L,
        EQUIPMENT_STATES.DESPACHADO,
        EQUIPMENT_STATES.DEPOSITO,
        EQUIPMENT_STATES.RENDIDO,
        EQUIPMENT_STATES.PACTADO,
      ];
      if (!autorize) {
        WITHOUT_STATES.push(EQUIPMENT_STATES.AUTORIZAR);
      }
      // si aun no hay estados en el store, paso a filtrar los estados que recibi del response y setear estados en el estore
      // para que este disponible para llenar las options del input select estado
      const filterStates = equipment_states.value.filter((val) => !WITHOUT_STATES.includes(val.id));
      await setPropEquipment({
        key: 'equipmentInputsTemplate', index: 'equipment_state_id', prop: 'options', value: filterStates,
      });
      await setPropEquipment({ key: 'states', value: filterStates });
    };

    const templateTraduccionEquipos = computed(() => {
        if(!currentEquipment.value || currentEquipment.value.length === 0) return null
        let templates = currentEquipment.value.enterprise.templates.find((t) => t.type_template_id === TYPE_TEMPLATES.TRADUCCION_CAMPOS_EQUIPOS_A_GESTION)
        if(templates){
            templates = JSON.parse(templates.template)
            return templates
        }
        return null
    })

    const completeForm = async () => {
      const { autorize } = route.params;
      if (autorize && autorize === '1') {
        isAutorize.value = true;
        await setTemplate();
        prepareToAuthorize();
        getStates(true);
      } else {
        await setTemplate();
        getStates();
        let enterprise_id = null;
        // si esta vacio el equipo actual (vuex), trato de buscarlo en el local storage, asi le saco el id
        // de la empresa y los valores del equipo para completar
        if (objEmpty(currentEquipment.value)) {
          const currentEquipmentLs = await hasLocalStorage({ key: 'currentEquipment' });
          if (currentEquipmentLs) {
            const { enterprise_id: id } = currentEquipmentLs;
            enterprise_id = id;
            await setPropEquipment({ key: 'currentEquipment', value: currentEquipmentLs });
          }
        } else {
          const { enterprise_id: id } = currentEquipment.value;
          enterprise_id = id;
        }
        if (templateTraduccionEquipos.value.length > 0) {
          for (let i = 0; i < templateTraduccionEquipos.value.length; i += 1) {
            const val = templateTraduccionEquipos.value[i];
            // autocompleto los datos que ya tenga el equipo, y si lo completo, coloco disabled el input porque no tiene porqe modificar ese dato
            if (Object.prototype.hasOwnProperty.call(equipmentInputsTemplate.value, val.translateKey)) {
              const origin = val.originKey;
              const translate = val.translateKey;
              if (equipmentInputsTemplate.value[translate].value) {
                if (currentEquipment.value[origin]) {
                  const value = currentEquipment.value[origin];
                  setPropEquipment({
                    key: 'equipmentInputsTemplate', index: translate, prop: 'value', value,
                  });
                  setPropEquipment({
                    key: 'equipmentInputsTemplate', index: translate, prop: 'disabled', value: true,
                  });
                } else {
                  setPropEquipment({
                    key: 'equipmentInputsTemplate', index: translate, prop: 'value', value: null,
                  });
                  if (equipmentInputsTemplate.value[translate].disabled) {
                    setPropEquipment({
                      key: 'equipmentInputsTemplate', index: translate, prop: 'disabled', value: false,
                    });
                  }
                }
              } else if (currentEquipment.value[origin]) {
                const value = currentEquipment.value[origin];
                setPropEquipment({
                  key: 'equipmentInputsTemplate', index: translate, prop: 'value', value,
                });
                setPropEquipment({
                  key: 'equipmentInputsTemplate', index: translate, prop: 'disabled', value: true,
                });
              }
            }
          }
        }
      }
    };
    onMounted(() => {
      completeForm();
    });

    const loading = ref(false);
    const errorMessage = ref({});
    const saveEquipment = async () => {
      loading.value = true;
      await refForm.value.getValues();
      const equipmentData = {};
      const accesories = [];
      let error = false;
      Object.keys(equipmentInputsTemplate.value).forEach(async (k) => {
        // reuno los accesorios por su contexto
        const { source } = equipmentInputsTemplate.value[k];
        if (source && source.context === 'accesory' && (formData.value.equipment_state_id === EQUIPMENT_STATES.RECUPERADO || formData.value.equipment_state_id === EQUIPMENT_STATES.AUTORIZAR)) {
          if (Object.prototype.hasOwnProperty.call(formData.value, k)) {
            const accesory = {
              accesory_id: equipmentInputsTemplate.value[k].optionValue.accesory, // busco el id del accesorio
              value: objEmpty(formData.value[k]) ? 0 : 1, // seteo el valor del accesorio
            };
            accesories.push(accesory);
          }
        // returno la informacion del equipo por su contexto
        } else if (source && source.context === 'equipmentData') {
          const { modify } = source;
          // si es un modificador tengo que revisar como esta la key que apunta este modificador
          if (modify) {
            const currentValueModify = formData.value[k]; // este es el input modificador
            if (currentValueModify === modify.condition) {
              // si el valor del modificador es igual al valor de la condicion y el valor de la key que apunta no esta lleno
              // si tiene error, se lo borro cuando pase por aca
              if (equipmentInputsTemplate.value[modify.key].error) {
                await setPropEquipment({
                  key: 'equipmentInputsTemplate', index: modify.key, prop: 'error', value: null,
                });
              }
              formData.value[modify.key] = modify.value;
              // si no se cumple la condicion del modificador para setear el valor
              // y la key que apunta el modificador esta vacia, seteo el error
            } else if (!formData.value[modify.key] && (formData.value.equipment_state_id === EQUIPMENT_STATES.RECUPERADO || formData.value.equipment_state_id === EQUIPMENT_STATES.AUTORIZAR)) {
              error = true;
              await setPropEquipment({
                key: 'equipmentInputsTemplate', index: modify.key, prop: 'error', value: modify.error,
              });
            } else if (equipmentInputsTemplate.value[modify.key].error) {
              await setPropEquipment({
                key: 'equipmentInputsTemplate', index: modify.key, prop: 'error', value: null,
              });
            }
          } else {
            equipmentData[k] = formData.value[k];
          }
        }
      });
      if (error) {
        instance.value.$toast.info('Informacion incompleta', {
          duration: 1500,
          dismissible: true,
        });
        loading.value = false;
        return;
      }
      const save = { ...{ accesories } };
      const { enterprise_id, identificacion, id } = currentEquipment.value;
      const { hash, order } = route.params;
      save.enterprise = enterprise_id;
      save.hash = hash;
      save.identificacion = identificacion;
      save.order = order;
      equipmentData.equipment_id = id;
      save.equipments = [equipmentData];

      const response = await saveEquipmentRepo(save);
      const { equipment, errors } = response;
      if (errors) {
        errorMessage.value = errors;
        instance.value.$toast.error('Ocurrio un error', {
          duration: 2000,
          dismissible: true,
        });
      } else {
        errorMessage.value = {};
        await addTransactionEquipment(equipment);
        /* eslint no-use-before-define: ["error", { "variables": false }] */
        if (formData.value.equipment_state_id !== EQUIPMENT_STATES.AUTORIZAR) {
          // solo cuando sea diferente a autorizar, para no sacar ese id de los equipos disponnibles
          await removeEquipmentToAvailables(equipment);
        }

        await refForm.value.reset();
        await store.dispatch('equipments/hiddenFieldsToNegative', true);
        await store.dispatch('equipments/setPropEquipment', {
          key: 'equipmentInputsTemplate', index: 'equipment_state_id', prop: 'value', value: null,
        });
        await store.dispatch('equipments/setPropEquipment', {
          key: 'equipmentInputsTemplate', index: 'equipment_state_id', prop: 'disabled', value: false,
        });

        instance.value.$toast.success('Guardado correctamente', {
          duration: 1500,
          dismissible: true,
        });
        exit(true);
      }
      loading.value = false;
    };

    const addTransactionEquipment = async (equipment) => {
      const { equipments, equipmentAvailables, originalEquipments } = store.state.equipments;
      const newEquipments = [...equipments, ...[equipment]];
      await setPropEquipment({ key: 'equipments', value: newEquipments });
      localStorage.setItem('equipments', JSON.stringify(newEquipments));

      const original = [...equipmentAvailables.filter((val) => val.id === equipment.equipment_id), ...originalEquipments];
      await setPropEquipment({ key: 'originalEquipments', value: original });
      localStorage.setItem('originalEquipments', JSON.stringify(original));
    };

    const removeEquipmentToAvailables = async (equipment) => {
      const availables = store.state.equipments.equipmentAvailables.filter(({ id }) => id !== equipment.equipment_id);
      await setPropEquipment({ key: 'equipmentAvailables', value: availables });
      emit('subtractCounter');
    };
    return {
      refForm, formData, saveEquipment, equipmentInputsTemplate, exit, route_from_path, setForm, errorMessage, objEmpty, loading, formValid,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
    /* eslint-disable no-param-reassign */
      vm.route_from_path = from.name;
    });
  },
};
</script>

<style>

</style>
